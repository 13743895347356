/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import thunk from 'redux-thunk'

import authReducer from '@/modules/auth/reducers/authReducer'
import crmReducer from '@/modules/crm/reducers/crmReducer'

const usersFilter = createBlacklistFilter('users', ['items'])

const projectFilter = createBlacklistFilter('project', ['items'])

const persistConfig = {
  key: 'crm',
  storage,
  whitelist: ['auth', 'me'],
  transforms: [usersFilter, projectFilter]
}

const rootReducer = combineReducers({
  auth: authReducer,
  crm: crmReducer
  // me: meReducer,
  // project: projectReducer,
  // log: logReducer,
  // users: userReducer
  // App
})

const middlewares: any = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
