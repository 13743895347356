import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd/lib/radio'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { updateFilter } from '../reducers/staffReducer'

const SortByTime: React.FC = () => {
  const dispatch = useDispatch<any>()
  const filterState = useSelector((state: RootState) => state.crm.filter) as any
  const [value, setValue] = useState(filterState?.sorts || '-createdAt')

  const handleSortChange = (e: RadioChangeEvent) => {
    const sort = e.target.value
    setValue(sort)
    dispatch(updateFilter({ sorts: sort }))
  }

  return (
    <Radio.Group onChange={handleSortChange} value={value} className='flex items-center'>
      <Radio value='-createdAt'>Latest</Radio>
      <Radio value='createdAt'>Oldest</Radio>
    </Radio.Group>
  )
}

export default SortByTime
