import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { injectStore } from '@/common/api'
import { themeConfig } from '@/common/theme'
import { persistor, store } from '@/store'

import AppRoot from './App'
import reportWebVitals from './reportWebVitals'

injectStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider theme={themeConfig}>
          <AppRoot />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
)

reportWebVitals()
