/* eslint-disable @typescript-eslint/no-unused-vars */
import { message, Spin, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { formatTimeAgo } from '@/common/utils'

import DetailsTab from './components/DetailsTab'
import SettingsTab from './components/SettingsTab'
import { deleteApp, getAppDetail, getAppLogs, updateApp } from './reducers/projectReducer'

const ProjectDetail: React.FC = () => {
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<any>({})
  const [logs, setLogs] = useState<any>({})

  const fetchData = async (id: string) => {
    try {
      setIsFetching(true)
      const rs = await dispatch(getAppDetail({ id })).unwrap()
      if (rs) {
        setData({ ...rs, formatTime: formatTimeAgo(rs.createdAt) })
      }

      const logsRs = await dispatch(getAppLogs({ id })).unwrap()
      if (logsRs && logsRs.items) {
        const groupedLogs: any = {
          1: [],
          2: [],
          3: [],
          4: [],
          5: []
        }

        logsRs.items.forEach((log: any) => {
          if (groupedLogs[log.level]) {
            groupedLogs[log.level].push(log)
          }
        })

        setLogs({
          ...logsRs,
          groupedLogs
        })
      }
    } catch (error: any) {
      message.error('Failed to get project detail!')
    } finally {
      setIsFetching(false)
    }
  }

  const handleUpdateAppName = async (appName: string) => {
    if (!params.id) return
    await dispatch(updateApp({ id: params.id, name: appName })).unwrap()
    setData((prevData: any) => ({ ...prevData, name: appName }))
  }

  const handleDeleteApp = async () => {
    if (!params.id) return
    await dispatch(deleteApp({ id: params.id })).unwrap()
    navigate('/projects')
  }

  useEffect(() => {
    if (params.id) {
      fetchData(params.id)
    }
  }, [params.id])

  const tabItems = [
    {
      label: 'Details',
      key: 'details',
      children: <DetailsTab data={data} logs={logs} updateAppName={handleUpdateAppName} initialAppName={data?.name} />
    },
    {
      label: 'Settings',
      key: 'settings',
      children: (
        <div>
          <h1 className='mt-0 flex-1 text-2xl font-bold'>Project name: {data?.name}</h1>
          <SettingsTab handleDeleteApp={handleDeleteApp} />
        </div>
      )
    }
  ]

  return (
    <div className='w-full p-6 pl-0'>
      <Spin spinning={isFetching}>
        <Tabs defaultActiveKey='details' items={tabItems} tabPosition='left' />
      </Spin>
    </div>
  )
}

export default ProjectDetail
