import type { TableProps } from 'antd'
import { Button, Drawer, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsPencilSquare } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

import AddButton from './components/AddButton'
// import CreateAppModal from './components/CreateProject'
import Search from './components/Search'
import { getCustomers, updateFilter } from './reducers/crmReducer'

type ColumnsType<T> = TableProps<T>['columns']

interface DataType {
  id: string
  phone_number: string
  dob: string
  address: string
  name: string
  createdAt: string
  user: {
    email: string
  }
}

const CRMIndex: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const { pathname } = useLocation()
  const crmState = useSelector((state: RootState) => state.crm) as any
  const [loading, setLoading] = useState(false)
  const showDrawer = pathname.includes('/crm/add')

  const fetchData = async () => {
    setLoading(true)

    try {
      await dispatch(getCustomers())
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  const getSortOrder = (sorts: string, column: string) => {
    if (sorts === column) {
      return 'ascend'
    }
    if (sorts === `-${column}`) {
      return 'descend'
    }
    return undefined
  }

  const handleCloseAdd = () => {
    navigate('/crm')
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%'
    },
    {
      title: 'Birthday',
      dataIndex: 'dob',
      render: (value) => value || '-'
    },
    {
      title: 'Email',
      dataIndex: 'user',
      render: (record: any) => record?.email
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number'
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      width: '15%',
      sortOrder: getSortOrder(crmState.filter.sorts, 'createdAt')
    },
    {
      width: '15%',
      hidden: true,
      render: (record: DataType) => (
        <div className='center'>
          <Link to={`/projects/${record.id}`}>
            <Button type='text'>
              <BsPencilSquare />
            </Button>
          </Link>
        </div>
      )
    }
  ]

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    const formatOrder = (sort: any) => {
      if (Array.isArray(sort)) {
        return undefined
      }

      if (sort.order) {
        return sort.order === 'descend' ? `-${sort.field}` : sort.field
      }

      return undefined
    }

    const newSortOrder = formatOrder(sorter)

    dispatch(
      updateFilter({
        page: pagination.current,
        limit: pagination.pageSize,
        sorts: newSortOrder
      })
    )
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(crmState.filter)])

  return (
    <div className='p-6'>
      <div className='mb-5 flex items-center justify-between'>
        <h2 className='m-0'>Customers</h2>

        <AddButton />
      </div>

      <div className='mb-5 flex items-center justify-between'>
        <div />

        <div className='flex'>
          <Search />
        </div>
      </div>

      <Drawer title='Create a new Customer' placement='right' onClose={handleCloseAdd} width={500} destroyOnClose open={showDrawer}>
        <Outlet />
      </Drawer>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        onRow={(record) => {
          return {
            onClick: () => navigate(`/crm/${record.id}`)
          }
        }}
        rowClassName='cursor-pointer'
        dataSource={crmState.items}
        pagination={{
          current: crmState.filter.page,
          pageSize: crmState.filter.limit,
          total: crmState.total,
          showSizeChanger: true
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default CRMIndex
