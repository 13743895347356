import { Button, DatePicker, Form, Input, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { checkIsPhone } from '@/common/utils'

import { createCustomer } from '../reducers/crmReducer'

const AddCustomer: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = async (formValue: any) => {
    try {
      await dispatch(createCustomer(formValue)).unwrap()

      form.resetFields()

      message.success('Create customer successfully')

      navigate('/crm')
    } catch (error: any) {}
  }

  const handleSubmitClick = async () => {
    try {
      const values = await form.validateFields()
      values.dob = values.dob?.format('DD/MM/YYYY')

      if (!checkIsPhone(values.phone_number)) {
        form.setFields([{ name: 'phone_number', errors: ['Please enter a valid phone number'] }])
        return
      }

      handleSubmit(values)
    } catch (error) {}
  }

  return (
    <div>
      <Form layout='vertical' requiredMark={false} form={form}>
        <Form.Item
          name='email'
          label='Email'
          rules={[
            { required: true, message: 'Please enter user email' },
            { type: 'email', message: 'Please enter a validate email!' }
          ]}
        >
          <Input placeholder='Please enter user email' />
        </Form.Item>
        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please enter user name' }]}>
          <Input placeholder='Please enter user name' />
        </Form.Item>

        <Form.Item name='dob' label='Date of birth'>
          <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} getPopupContainer={(trigger) => trigger.parentElement!} />
        </Form.Item>

        <Form.Item name='phone_number' label='Phone number' rules={[{ required: true, message: 'Please enter phone number' }]}>
          <Input placeholder='Please enter phone number' />
        </Form.Item>

        <Form.Item name='address' label='Address'>
          <Input.TextArea placeholder='Please enter address' />
        </Form.Item>
      </Form>

      <div className='flex justify-end'>
        <Button type='primary' onClick={handleSubmitClick}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export default AddCustomer
