import type { TableProps } from 'antd'
import { Button, Card, Col, message, Pagination, Row, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsBoxSeamFill, BsGridFill, BsList, BsPencilSquare } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { formatTimeAgo } from '@/common/utils'
import type { RootState } from '@/store'

import CreateAppModal from './components/CreateProject'
import Search from './components/Search'
import SortByTime from './components/SortByTime'
import { getApps, setFilter, setGridView } from './reducers/projectReducer'

type ColumnsType<T> = TableProps<T>['columns']

interface DataType {
  name: string
  apiKey: string
  createdAt: string
  id: string
}

const truncateApiKey = (key: string, startLength: number, endLength: number) => {
  if (key.length <= startLength + endLength) {
    return key
  }
  return `${key.slice(0, startLength)}...${key.slice(-endLength)}`
}

const ProjectManager: React.FC = () => {
  const dispatch = useDispatch<any>()
  const projectState = useSelector((state: RootState) => state.project) as any
  const [data, setData] = useState<DataType[]>(projectState.items)
  const { isGridView } = projectState
  const [loading, setLoading] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  const fetchData = async () => {
    if (data?.length === 0) {
      setLoading(true)
    }

    try {
      const rs = await dispatch(getApps()).unwrap()
      if (rs && rs.items && data !== rs.items) {
        setData(rs.items)
      }
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  const getSortOrder = (sorts: string, column: string) => {
    if (sorts === column) {
      return 'ascend'
    }
    if (sorts === `-${column}`) {
      return 'descend'
    }
    return undefined
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'App Name',
      dataIndex: 'name',
      width: '25%'
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      render: (value) => (
        <div>
          <CopyToClipboard
            text={value}
            onCopy={(_, result) => {
              if (result) {
                messageApi.success('API Key copied to clipboard!')
              } else {
                messageApi.error('Failed to copy API Key!')
              }
            }}
          >
            <Tooltip title='Click to copy'>
              <span className='cursor-pointer'>{truncateApiKey(value, 10, 10)}</span>
            </Tooltip>
          </CopyToClipboard>
        </div>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      hidden: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      width: '15%',
      sortOrder: getSortOrder(projectState.filter.sorts, 'createdAt')
    },
    {
      width: '15%',
      render: (record: DataType) => (
        <div className='center'>
          <Link to={`/projects/${record.id}`}>
            <Button type='text'>
              <BsPencilSquare />
            </Button>
          </Link>
        </div>
      )
    }
  ]

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    const formatOrder = (sort: any) => {
      if (Array.isArray(sort)) {
        return undefined
      }

      if (sort.order) {
        return sort.order === 'descend' ? `-${sort.field}` : sort.field
      }

      return undefined
    }

    const newSortOrder = formatOrder(sorter)

    dispatch(
      setFilter({
        page: pagination.current,
        limit: pagination.pageSize,
        sorts: newSortOrder
      })
    )

    if (pagination.pageSize !== projectState.filter.limit) {
      setData([])
    }
  }

  const handleChangeView = (value: boolean) => {
    dispatch(setGridView(value))
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(projectState.filter), projectState.total])

  return (
    <div>
      {contextHolder}

      <div className='mb-5 flex items-center justify-between'>
        <h2 className='m-0'>Project Manager</h2>

        <CreateAppModal />
      </div>

      <div className='mb-5 flex items-center justify-between'>
        <div>
          <Tooltip title='Grid view'>
            <Button type={isGridView ? 'primary' : 'default'} onClick={() => handleChangeView(true)} style={{ marginRight: 8 }}>
              <BsGridFill />
            </Button>
          </Tooltip>

          <Tooltip title='List view'>
            <Button type={!isGridView ? 'primary' : 'default'} onClick={() => handleChangeView(false)}>
              <BsList />
            </Button>
          </Tooltip>
        </div>

        <div className='flex'>
          <SortByTime />
          <Search />
        </div>
      </div>

      {isGridView ? (
        <div>
          <Row gutter={[32, 32]} className='mb-5'>
            {data?.map((project) => (
              <Col xs={24} sm={12} lg={6} key={project.id}>
                <Card
                  title={project.name}
                  bordered
                  hoverable
                  extra={
                    <Link className='text-primary hover:text-primary-400' to={`/projects/${project.id}`}>
                      Detail
                    </Link>
                  }
                  styles={{ header: { backgroundColor: '#f0f2f5' } }}
                >
                  <div className='flex flex-col gap-3'>
                    <BsBoxSeamFill size={32} fill='#5379FF' />
                    <span className='text-xs'>Created At: {formatTimeAgo(new Date(project.createdAt))}</span>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <div className='center'>
            <Pagination
              current={projectState.filter.page}
              pageSize={projectState.filter.limit}
              total={projectState.total}
              showSizeChanger
              pageSizeOptions={['8', '16', '24', '32', '40']}
              onChange={(page, pageSize) => {
                dispatch(
                  setFilter({
                    page,
                    limit: pageSize
                  })
                )
              }}
            />
          </div>
        </div>
      ) : (
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={{
            current: projectState.filter.page,
            pageSize: projectState.filter.limit,
            total: projectState.total,
            showSizeChanger: true,
            pageSizeOptions: ['8', '16', '24', '32', '40']
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
    </div>
  )
}

export default ProjectManager
