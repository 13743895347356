import React from 'react'

import CustomerIndex from './CustomerIndex'

export const CustomerRoutes = [
  {
    path: 'customers',
    element: <CustomerIndex />
  }
]
