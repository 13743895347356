import type { InputRef } from 'antd'
import { Button, Input, message, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { sleep } from '@/common/utils'

import { createApp } from '../reducers/projectReducer'

const CreateApp: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [appName, setAppName] = useState<string>('')
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const inputRef = useRef<InputRef>(null)
  const [messageApi, contextHolder] = message.useMessage()

  const handleCreate = async () => {
    if (!appName) {
      messageApi.warning('Please enter app name!')
      return
    }

    try {
      setConfirmLoading(true)
      const rs = await dispatch(createApp({ name: appName })).unwrap()
      if (rs) {
        messageApi.success('App created successfully!')
        setAppName('')
        setCreateModalOpen(false)
      }
    } catch (error: any) {
      messageApi.error('Failed to create app!')
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <div>
      {contextHolder}
      <Modal
        title='Create app'
        open={isCreateModalOpen}
        onOk={handleCreate}
        confirmLoading={confirmLoading}
        onCancel={() => setCreateModalOpen(false)}
        okText='Create'
      >
        <Input placeholder='App name' value={appName} onChange={(e) => setAppName(e.target.value)} ref={inputRef} />
      </Modal>
      <Button
        type='primary'
        onClick={() => {
          setCreateModalOpen(true)
          sleep(100).then(() => inputRef.current?.focus())
        }}
      >
        Create app
      </Button>
    </div>
  )
}

export default CreateApp
