import { Input } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { updateFilter } from '../reducers/staffReducer'

const Search: React.FC = () => {
  const dispatch = useDispatch<any>()
  const filterState = useSelector((state: RootState) => state.crm.filter) as any
  const [value, setValue] = useState(filterState?.search || '')

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(updateFilter({ search: text }))
    }, 500),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setValue(text)
    handleSearch(text)
  }

  return <Input placeholder='Search' value={value} onChange={handleChange} className='w-[200px]' />
}

export default Search
