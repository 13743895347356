import React from 'react'

type Props = {
  label: string
  value: string
}

const InfoItem: React.FC<Props> = ({ label, value }) => {
  return (
    <div className='mt-4 flex flex-col gap-0.5'>
      <span className='text-sm font-medium text-gray-500'>{label}</span>
      <span className='ps-4'>{value}</span>
    </div>
  )
}

export default InfoItem
