import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

export interface ProjectState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
  }
  isGridView?: boolean
}

const initialState: ProjectState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 8,
    search: undefined,
    sorts: '-createdAt'
  },
  isGridView: true
}

/*
 *****************************************
 *
 *
 */

export const getApps = createAsyncThunk('project/getList', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const params = { ...state.project.filter }

    if (!params.search) {
      delete params.search
    }
    const response = await api.get('apps', {
      params
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * *******************************
 * @function getAppDetail
 * @param {object} params - The object containing parameters for getting the app detail.
 * @param {object} thunkAPI - The object containing methods related to executing the thunk.
 * @returns {Promise<any>} - Returns a promise containing the response data.
 * *******************************
 */

export const getAppDetail = createAsyncThunk('project/getDetail', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.get(`apps/${params.id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * *******************************
 * @function createApp
 * @param {object} params - The object containing parameters for creating the app.
 * @param {object} thunkAPI - The object containing methods related to executing the thunk.
 * @returns {Promise<any>} - Returns a promise containing the response data.
 * *******************************
 */
export const createApp = createAsyncThunk('project/create', async (params: { name: string }, { rejectWithValue }) => {
  try {
    const response = await api.post('apps', params)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * *******************************
 * @function deleteApp
 * @param {object} params - The object containing parameters for deleting the app.
 * @param {object} thunkAPI - The object containing methods related to executing the thunk.
 * @returns {Promise<boolean>} - Returns true if the app is deleted successfully, otherwise returns false.
 * *******************************
 */
export const deleteApp = createAsyncThunk('project/delete', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`apps/${params.id}`)
    if (response) {
      return true
    }
    return false
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * *******************************
 * @function updateApp
 * @param {object} params - The object containing parameters for updating the app.
 * @param {object} thunkAPI - The object containing methods related to executing the thunk.
 * @returns {Promise<any>} - Returns a promise containing the response data.
 * *******************************
 */
export const updateApp = createAsyncThunk('project/update', async (params: { id: string; name: string }, { rejectWithValue }) => {
  try {
    const response = await api.put(`apps/${params.id}`, { name: params.name })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

// get logs of the app
export const getAppLogs = createAsyncThunk('project/getLogs', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.get(`logs`, {
      params: {
        appId: params.id
      }
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const keySlice = createSlice({
  name: 'project',

  // blacklist: items,

  initialState,

  reducers: {
    setGridView: (state, action) => {
      return {
        ...state,
        isGridView: action.payload
      }
    },

    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getApps.fulfilled, (state, action) => {
      const { total, items } = action.payload as any
      state.total = total
      state.items = items
    })

    builder.addCase(createApp.fulfilled, (state) => {
      state.total += 1
    })
  }
})

export const { setGridView, setFilter } = keySlice.actions
export default keySlice.reducer
