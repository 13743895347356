import type { TableProps } from 'antd'
import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsPencilSquare } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import type { RootState } from '@/store'

// import CreateAppModal from './components/CreateProject'
import Search from './components/Search'
import SortByTime from './components/SortByTime'
import { getStaffs, updateFilter } from './reducers/staffReducer'

type ColumnsType<T> = TableProps<T>['columns']

interface DataType {
  name: string
  apiKey: string
  createdAt: string
  id: string
}

const StaffIndex: React.FC = () => {
  const dispatch = useDispatch<any>()
  const crmState = useSelector((state: RootState) => state.crm) as any
  const [data, setData] = useState<DataType[]>(crmState.items)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if (data?.length === 0) {
      setLoading(true)
    }

    try {
      const rs = await dispatch(getStaffs()).unwrap()
      if (rs && rs.items && data !== rs.items) {
        setData(rs.items)
      }
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  const getSortOrder = (sorts: string, column: string) => {
    if (sorts === column) {
      return 'ascend'
    }
    if (sorts === `-${column}`) {
      return 'descend'
    }
    return undefined
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%'
    },
    {
      title: 'Email',
      dataIndex: 'user',
      render: (record: any) => record?.email
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number'
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      width: '15%',
      sortOrder: getSortOrder(crmState.filter.sorts, 'createdAt')
    },
    {
      width: '15%',
      hidden: true,
      render: (record: DataType) => (
        <div className='center'>
          <Link to={`/projects/${record.id}`}>
            <Button type='text'>
              <BsPencilSquare />
            </Button>
          </Link>
        </div>
      )
    }
  ]

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    const formatOrder = (sort: any) => {
      if (Array.isArray(sort)) {
        return undefined
      }

      if (sort.order) {
        return sort.order === 'descend' ? `-${sort.field}` : sort.field
      }

      return undefined
    }

    const newSortOrder = formatOrder(sorter)

    dispatch(
      updateFilter({
        page: pagination.current,
        limit: pagination.pageSize,
        sorts: newSortOrder
      })
    )

    if (pagination.pageSize !== crmState.filter.limit) {
      setData([])
    }
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(crmState.filter), crmState.total])

  return (
    <div className='p-6'>
      <div className='mb-5 flex items-center justify-between'>
        <h2 className='m-0'>Customers</h2>

        {/* <CreateAppModal /> */}
      </div>

      <div className='mb-5 flex items-center justify-between'>
        <div />

        <div className='flex'>
          <SortByTime />
          <Search />
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          current: crmState.filter.page,
          pageSize: crmState.filter.limit,
          total: crmState.total,
          showSizeChanger: true
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default StaffIndex
