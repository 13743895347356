import React from 'react'

import AddCustomer from './components/AddCustomer'
import CRMIndex from './CRMIndex'
import CustomerDetail from './partial/detail/CustomerDetail'

export const CRMRoutes = [
  {
    path: 'crm',
    element: <CRMIndex />,
    children: [
      {
        path: 'add',
        element: <AddCustomer />
      }
    ]
  },

  {
    path: 'crm/:customerId',
    element: <CustomerDetail />
  }
]
