import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

export interface ProjectState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
  }
}

const initialState: ProjectState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 20,
    search: undefined,
    sorts: '-createdAt'
  }
}

/*
 *****************************************
 *
 *
 */

export const getStaffs = createAsyncThunk('staff/getList', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const params = { ...state.crm.filter }

    if (!params.search) {
      delete params.search
    }
    const response = await api.get('staffs', {
      params
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getAppDetail = createAsyncThunk('project/getDetail', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.get(`apps/${params.id}`)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createApp = createAsyncThunk('project/create', async (params: { name: string }, { rejectWithValue }) => {
  try {
    const response = await api.post('apps', params)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteApp = createAsyncThunk('project/delete', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`apps/${params.id}`)
    if (response) {
      return true
    }
    return false
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateApp = createAsyncThunk('project/update', async (params: { id: string; name: string }, { rejectWithValue }) => {
  try {
    const response = await api.put(`apps/${params.id}`, { name: params.name })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getAppLogs = createAsyncThunk('project/getLogs', async (params: { id: string }, { rejectWithValue }) => {
  try {
    const response = await api.get(`logs`, {
      params: {
        appId: params.id
      }
    })
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const slice = createSlice({
  name: 'crm',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getStaffs.fulfilled, (state, action) => {
      const { total, items } = action.payload as any
      state.total = total
      state.items = items
    })

    builder.addCase(createApp.fulfilled, (state) => {
      state.total += 1
    })
  }
})

export const { setFilter, updateFilter } = slice.actions
export default slice.reducer
