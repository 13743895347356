import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getCustomerDetail } from '../../reducers/crmReducer'
import CustomerProfile from '../components/CustomerProfile'
import Overview from '../components/Overview'

const CustomerDetail: React.FC = () => {
  const dispatch = useDispatch<any>()
  const params = useParams<{ customerId: string }>()

  const fetchData = async (id: string) => {
    await dispatch(getCustomerDetail({ id }))
  }

  useEffect(() => {
    if (params?.customerId) {
      fetchData(params.customerId)
    }
  }, [params.customerId])

  return (
    <div className='flex h-full'>
      <CustomerProfile />

      <Overview />
    </div>
  )
}

export default CustomerDetail
