import React from 'react'

import ProjectDetail from './ProjectDetail'
import ProjectManager from './ProjectManager'

export const ProjectRoutes = [
  {
    path: 'projects',
    element: <ProjectManager />
  },
  {
    path: 'projects/:id',
    element: <ProjectDetail />
  }
]
