import { Avatar } from 'antd'
import React from 'react'
import { BsPerson } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

import type CustomerDetail from '../../types/crm.type'
import InfoItem from './InfoItem'

const CustomerProfile: React.FC = () => {
  const info: CustomerDetail = useSelector((state: RootState) => state.crm.cDetail)

  if (!info) {
    return null
  }

  return (
    <div className='h-full basis-1/4 border-r border-solid border-gray-300 p-6'>
      {/* Head profile */}
      <div className='flex gap-4 border-b border-solid border-gray-300 pb-5'>
        <Avatar size={{ xs: 32, md: 44, xl: 52, xxl: 60 }} className='shrink-0 bg-primary' icon={<BsPerson />} />
        <div className='flex flex-col'>
          <span className='text-wrap text-lg font-medium'>{info.name}</span>
          <span className='text-sm'>{info.user.email}</span>
        </div>
      </div>

      {/* Body profile */}
      <div className='mt-4'>
        <div className='text-lg font-bold'>Contact information:</div>

        <InfoItem label='Email:' value={info.user.email} />

        <InfoItem label='Phone number:' value={info.phone_number} />

        <InfoItem label='Date of birth:' value={info.dob} />

        <InfoItem label='Address:' value={info.address} />

        <InfoItem label='Note:' value={info.note as string} />
      </div>
    </div>
  )
}

export default CustomerProfile
