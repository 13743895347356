import { Button, Card, Input, message, Statistic, Tooltip } from 'antd'
import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsCopy } from 'react-icons/bs'

import { ELevel } from '@/common/types/level.type'

type Props = {
  data: any
  logs: any
  updateAppName: Function
  initialAppName: string
}

const DetailsTab: React.FC<Props> = ({ data, logs, updateAppName, initialAppName }) => {
  const [appName, setAppName] = useState(initialAppName)
  const [isEditing, setIsEditing] = useState(false)
  const messageApi = message.useMessage()[0]

  let date = data.createdAt

  if (data.createdAt) {
    date = format(parseISO(date), 'HH:mm:ss, dd/MM/yyyy')
  }

  const handleSave = async () => {
    if (data.name === appName) {
      setIsEditing(false)
      return
    }
    try {
      await updateAppName(appName)
      messageApi.success('App name updated successfully!')
      setIsEditing(false)
    } catch {
      messageApi.error('Failed to update app name!')
    }
  }

  const getLogColors = (level: number) => {
    switch (level) {
      case ELevel.URGENT:
        return { backgroundColor: '#fff1f0', textColor: '#cf1322', borderColor: '#ffa39e' }
      case ELevel.ERROR:
        return { backgroundColor: '#fff2e8', textColor: '#d4380d', borderColor: '#ffbb96' }
      case ELevel.DEBUG:
        return { backgroundColor: '#5379FF', textColor: 'white', borderColor: '#5379FF' }
      case ELevel.WARN:
        return { backgroundColor: '#fffbe6', textColor: '#faad14', borderColor: '#ffe58f' }
      case ELevel.INFO:
        return { backgroundColor: '#e6f4ff', textColor: '#1677ff', borderColor: '#91d5ff' }
      default:
        return { backgroundColor: 'white', textColor: 'black', borderColor: 'black' }
    }
  }

  return (
    <div>
      <h1 className='mt-0 flex-1 text-2xl font-bold'>Project name: {data?.name}</h1>

      <div className='grid grid-cols-5 gap-4'>
        {Object.keys(logs.groupedLogs || {}).map((key) => {
          const level = parseInt(key, 10)
          const { backgroundColor, textColor, borderColor } = getLogColors(level)
          return (
            <div key={key} className='rounded-md p-2' style={{ backgroundColor, color: textColor, border: `1px solid ${borderColor}` }}>
              <Statistic title={ELevel[level]} value={logs.groupedLogs[key]?.length} valueStyle={{ color: textColor, textAlign: 'center' }} />
            </div>
          )
        })}
      </div>

      <div className='mb-5 mt-4'>Total logs: {logs?.items?.length || 0}</div>

      <Card type='inner' title='Project details' className='overflow-hidden rounded-lg shadow-lg'>
        <Card.Grid hoverable={false} style={{ width: '100%' }}>
          <div className='grid grid-cols-3 items-center'>
            <span>Project name: </span>
            <div className='col-span-2 flex items-center gap-2'>
              <Input value={appName} className='flex-1' size='large' onChange={(e) => setAppName(e.target.value)} onFocus={() => setIsEditing(true)} />
              {isEditing && <Button onClick={handleSave}>Save</Button>}
            </div>
          </div>
        </Card.Grid>
        <Card.Grid hoverable={false} style={{ width: '100%' }}>
          <div className='grid grid-cols-3 items-center'>
            <span>API Key: </span>
            <div className='col-span-2 flex gap-2'>
              <Input value={data?.apiKey} className='flex-1' size='large' disabled />
              <CopyToClipboard
                text={data?.apiKey}
                onCopy={(_, result) => {
                  if (result) {
                    messageApi.success('API Key copied to clipboard!')
                  } else {
                    messageApi.error('Failed to copy API Key!')
                  }
                }}
              >
                <Tooltip title='Click to copy'>
                  <Button type='text' size='large'>
                    <BsCopy />
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
        </Card.Grid>
        {date && (
          <Card.Grid hoverable={false} style={{ width: '100%' }}>
            <div className='grid grid-cols-3 items-center'>
              <span>Created at: </span>
              <span>{date}</span>
            </div>
          </Card.Grid>
        )}
      </Card>
    </div>
  )
}

export default DetailsTab
