import { Button } from 'antd'
import React from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const AddButton: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()

  return (
    <Button type='primary' onClick={() => navigate('/crm/add')}>
      Add Customer
    </Button>
  )
}

export default AddButton
