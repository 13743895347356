import { Button, Card, message, Modal } from 'antd'
import React, { useState } from 'react'

type Props = {
  handleDeleteApp: Function
}

const SettingsTab: React.FC<Props> = ({ handleDeleteApp }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const messageApi = message.useMessage()[0]

  const handleConfirmDelete = async () => {
    setConfirmLoading(true)
    try {
      await handleDeleteApp()
      messageApi.success('App deleted successfully!')
      setModalOpen(false)
    } catch {
      messageApi.error('Failed to delete app!')
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <div>
      <Card type='inner' title='Actions' className='overflow-hidden rounded-lg shadow-lg'>
        <Card.Grid hoverable={false} style={{ width: '100%' }}>
          <div className='grid grid-cols-3 items-center'>
            <div className='col-span-2 flex flex-col text-danger'>
              <span className='mb-1 font-medium'>Delete Project</span>
              <span className='text-xs text-gray-500'>This action will delete all logs and data of this project.</span>
              <span className='text-xs text-gray-500'>This action cannot be undone.</span>
            </div>
            <span>
              <Button type='primary' danger onClick={() => setModalOpen(true)}>
                Delete Permanently
              </Button>
            </span>
          </div>
        </Card.Grid>
      </Card>

      <Modal
        title='Delete Project'
        open={modalOpen}
        onOk={handleConfirmDelete}
        onCancel={() => setModalOpen(false)}
        confirmLoading={confirmLoading}
        okText='Delete'
        cancelText='Cancel'
        okType='danger'
      >
        <p>Are you sure you want to delete this project?</p>
      </Modal>
    </div>
  )
}

export default SettingsTab
