/* eslint-disable react/no-unstable-nested-components */

import type { MenuProps } from 'antd'
import { Avatar, Divider, Dropdown, Layout, Menu, Space, theme } from 'antd'
import React, { useEffect } from 'react'
import { BsHouse, BsPeople, BsPerson } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { fetchMe } from '@/common/reducers/meReducer'
import type IUser from '@/common/types/user.type'
import { logout } from '@/modules/auth/reducers/authReducer'
import type { RootState } from '@/store'

const { useToken } = theme

const { Header, Content, Sider } = Layout
type MenuItem = Required<MenuProps>['items'][number]

const MainLayout: React.FC = () => {
  const user: IUser = useSelector((state: RootState) => state.auth.user)
  const navigate: NavigateFunction = useNavigate()

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const guestRoutes = ['/login']

  const dispatch = useDispatch<any>()

  const menuItems: MenuItem[] = [
    {
      key: '',
      icon: <BsHouse />,
      label: (
        <Link to='/' className='transition-none'>
          Dashboard
        </Link>
      )
    },
    (user?.roles?.includes(9) || user?.roles?.includes(2)) && {
      key: 'crm',
      icon: <BsPeople />,
      label: (
        <Link to='/crm' className='transition-none'>
          CRM
        </Link>
      )
    },

    user?.roles?.includes(9) && {
      key: 'staff',
      icon: <BsPerson />,
      label: (
        <Link to='/staff' className='transition-none'>
          Staff
        </Link>
      )
    }
  ].filter(Boolean) as MenuItem[]

  const handleLogout = async () => {
    await dispatch(logout())
    navigate('/login')
  }

  const profileItems: MenuItem[] = [
    {
      key: 'profile',
      label: <Link to='/profile'>Profile</Link>
    },
    {
      key: 'logout',
      label: <div onClick={handleLogout}>Logout</div>
    }
  ]

  const { token } = useToken()

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
  }

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none'
  }

  const location = useLocation()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchMe())
    }

    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [])

  useEffect(() => {
    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [location])

  return (
    <Layout className='min-h-screen'>
      <Header className='sticky top-0 z-10 flex items-center justify-between border-b border-solid border-gray-200 bg-white px-5'>
        <Link to='/'>
          <h3 className='m-0 mx-4 cursor-pointer text-[25px] font-bold text-primary'>CRM</h3>
        </Link>

        <Dropdown
          menu={{ items: profileItems }}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              <Space style={{ paddingInline: 16, paddingBlock: 8 }}>
                <div>
                  <div className='font-bold'>Admin</div>
                  <div className='text-tertiary'>{user?.email}</div>
                </div>
              </Space>
              <Divider style={{ margin: 0 }} />
              {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
            </div>
          )}
        >
          <div>
            {user && (
              <Avatar className='cursor-pointer bg-primary' size={40}>
                A
              </Avatar>
            )}
          </div>
        </Dropdown>

        {/* <Menu mode='horizontal' defaultSelectedKeys={['2']} style={{ flex: 1, minWidth: 0, border: 'none' }} /> */}
      </Header>
      <Layout hasSider>
        <Sider className='fixed bottom-0 left-0 z-10 h-screen overflow-auto' width={250} style={{ background: token.colorBgContainer, top: '64px' }}>
          <Menu
            mode='inline'
            defaultSelectedKeys={[location.pathname.split('/')[1]]}
            selectedKeys={[location.pathname.split('/')[1]]}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>
        <Layout className='ml-[250px] p-6'>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              background: token.colorBgContainer,
              borderRadius: token.borderRadiusLG
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MainLayout
