import React from 'react'

import StaffIndex from './StaffIndex'

export const StaffRoutes = [
  {
    path: 'staff',
    element: <StaffIndex />
  }
]
